import styles from './insta-auth.css';
import Link from './link';
import vibrate from '../modifiers/vibrate';
import externalLink from '../modifiers/external-link';
import { instaAuthUrl } from '../helpers/insta-auth-url';
import svgJar from 'ember-svg-jar/helpers/svg-jar';

const instagramAuthUrl = instaAuthUrl();

<template>
  <div class={{styles.insta-auth}}>
    <p>Allow <strong>Picture Wham</strong> to view your Instagram pictures</p>
    <Link
      class={{styles.insta}}
      href={{instagramAuthUrl}}
      {{externalLink}}
      {{vibrate}}
      data-test-instagram-authorize-link
    >{{svgJar "insta"}}&nbsp;Authorize Instagram</Link>
  </div>
</template>
