import Component from '@glimmer/component';
import styles from './cost.css';

import { use } from 'ember-resources';
import { RemoteData } from 'reactiveweb/remote-data';
import { COST_LOOKUP, EU_COUNTRY_CODES } from '../constants';

export default class CostComponent extends Component {
  DEFAULT_AREA = 'GB';

  @use request = RemoteData(() => 'https://ipinfo.io?token=1f1fb02d70fc5f');

  get cost() {
    return COST_LOOKUP[this.area] ?? COST_LOOKUP[this.DEFAULT_AREA];
  }

  get testCost() {
    return `${this.cost.currencySymbol}${this.cost.amount}`;
  }

  isEU(countryCode) {
    return EU_COUNTRY_CODES.includes(countryCode);
  }

  get area() {
    if (this.isEU(this.country)) {
      // higher postage cost to NL
      return this.country === 'NL' ? 'NL' : 'EU';
    }
    return this.country;
  }

  get country() {
    if (this.request.isLoading) {
      return 'GB';
    } else {
      return this.request.value?.country || 'GB';
    }
  }

  get currencySymbol() {
    return this.cost.currencySymbol;
  }

  get priceWhole() {
    return this.cost.amount.split('.')[0];
  }

  get priceFractional() {
    return this.cost.amount.split('.')[1];
  }

  delimiter = '.';

  <template>
    <div class={{styles.cost-div}} data-test-cost={{this.testCost}}>
      <div
        class="{{styles.currency}} {{styles.primary-secondary}}"
        data-content={{this.currencySymbol}}
      >{{this.currencySymbol}}</div>
      <div>
        <div class={{styles.total}}>
          <span
            class="{{styles.value}} {{styles.secondary-primary}}"
            data-content={{this.priceWhole}}
          >{{this.priceWhole}}</span><span
            class="{{styles.value}} {{styles.primary-secondary}}"
            data-content={{this.delimiter}}
          >{{this.delimiter}}</span>
          <span
            class="{{styles.value}} {{styles.secondary-primary}}"
            data-content={{this.priceFractional}}
          >{{this.priceFractional}}</span>
        </div>
        <span class={{styles.per-month}}>Per month</span>
      </div>
    </div>
  </template>
}
