import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class LoginRoute extends Route {
  @service router;
  @service session;

  queryParams = { token: { refreshModel: true } };

  beforeModel() {
    if (this.session.hasEmail) {
      return this.session.transitionToSessionState();
    }
  }

  async model({ token: jwt }) {
    if (jwt) {
      await this.session.authenticate('authenticator:custom', jwt);
      this.session.transitionToSessionState();
    }
  }
}
