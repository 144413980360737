import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class AuthenticatedRoute extends Route {
  @service api;
  @service sentry;

  async model() {
    try {
      let [username, photos] = await Promise.all([
        this.api.instagramUsername(),
        this.api.nextPhotos(),
      ]);
      return { username, photos };
    } catch (error) {
      this.sentry.captureException(error, {
        message: 'error showing instagram username in dashboard',
      });
      return { photos: { media: {}, timeRange: null } };
    }
  }
}
