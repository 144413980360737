import styles from './photo-preview.css';
import Component from '@glimmer/component';

export default class PhotoPreview extends Component {
  get columnStyle() {
    return styles[`grid-${this.args.columns}`] || '';
  }

  <template>
    <ul
      data-test-photo-preview
      class="{{styles.grid}} {{this.columnStyle}}"
      ...attributes
    >
      {{#each @photos as |photo|}}
        <li data-test-photo-preview-photo class={{styles.item}}><img
            class={{styles.img}}
            src={{photo.url}}
            alt={{photo.desc}}
          /></li>
      {{/each}}
    </ul>
  </template>
}
