import styles from './loader.css';

<template>
  <span class={{styles.loader}} ...attributes>
    <div class="{{styles.container}} {{styles.one}}">
      <span class="{{styles.dot}}"></span>
    </div>
    <div class="{{styles.container}} {{styles.two}}">
      <span class="{{styles.dot}}"></span>
    </div>
    <div class="{{styles.container}} {{styles.three}}">
      <span class="{{styles.dot}}"></span>
    </div>
  </span>
</template>
