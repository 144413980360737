import Component from '@glimmer/component';
import RouteTemplate from 'ember-route-template';
import pageTitle from 'ember-page-title/helpers/page-title';
import { LinkTo } from '@ember/routing';
import svgJar from 'ember-svg-jar/helpers/svg-jar';
import { tracked } from '@glimmer/tracking';
import { hash } from '@ember/helper';
import { service } from '@ember/service';
import { dropTask, timeout } from 'ember-concurrency';
import perform from 'ember-concurrency/helpers/perform';

import { LOGIN_FORM_TIMEOUT } from 'frontend/constants';
import Container from 'frontend/components/container';
import Header from 'frontend/components/header';
import LoginForm from 'frontend/components/login-form';
import Section from 'frontend/components/section';
import SectionFooter from 'frontend/components/section-footer';
import Sections from 'frontend/components/sections';

import styles from './login.css';

class LoginRouteComponent extends Component {
  @tracked testToken;
  @service api;

  submitTask = dropTask(async (event) => {
    let payload = await this.api.login(new FormData(event.target));
    // payload returned in tests to simulate email link
    // payload returned in dev and staging env to facilitate Facebook reviewers testing
    // where they don't have access to test email account due to 'suspicious' activity
    if (payload) {
      this.testToken = payload.jwt;
    }
    await timeout(LOGIN_FORM_TIMEOUT);
  });

  <template>
    {{pageTitle "Login"}}

    <Container>
      <:header>
        <Header>
          <LinkTo
            data-test-menu-link
            aria-label="Show menu"
            @route="menu"
          >{{svgJar "btn-menu"}}</LinkTo>
        </Header>
      </:header>

      <:main>
        <Sections>
          <Section class={{styles.first-section}}>
            <LoginForm
              class={{styles.form}}
              @onSubmit={{perform this.submitTask}}
              @submitTaskInstance={{this.submitTask.last}}
            />

            {{#if this.testToken}}
              <LinkTo
                @query={{hash token=this.testToken}}
                data-test-login-jwt-link
              >Test Token (only available in staging)</LinkTo>
            {{/if}}

          </Section>
          <SectionFooter />
        </Sections>
      </:main>
    </Container>
  </template>
}

export default RouteTemplate(LoginRouteComponent);
