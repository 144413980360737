import RouteTemplate from 'ember-route-template';
import pageTitle from 'ember-page-title/helpers/page-title';
import { LinkTo } from '@ember/routing';
import Container from 'frontend/components/container';
import Header from 'frontend/components/header';
import Section from 'frontend/components/section';
import Sections from 'frontend/components/sections';
import SectionFooter from 'frontend/components/section-footer';
import Link from 'frontend/components/link';
import { link } from 'ember-link';
import { on } from '@ember/modifier';
import svgJar from 'ember-svg-jar/helpers/svg-jar';

import styles from './404.css';

export default RouteTemplate(
  <template>
    {{pageTitle "404"}}

    <Container>
      <:header>
        <Header>
          <LinkTo
            data-test-menu-link
            aria-label="Show menu"
            @route="menu"
          >{{svgJar "btn-menu"}}</LinkTo>
        </Header>
      </:header>
      <:main>
        <Sections>
          <Section class={{styles.not-found}}>
            <img
              alt="rattle can spraying 404"
              src="/images/illustrations/404.svg"
            />
            <h1>Page not found!</h1>
            <p>We're sorry. The page you're looking for has either been removed
              or doesn't exist.</p>
            {{#let (link "index") as |l|}}
              <Link
                class={{styles.btn}}
                href={{l.url}}
                {{on "click" l.open}}
                data-test-return-homepage
              >Return to Homepage</Link>
            {{/let}}
          </Section>
          <SectionFooter />
        </Sections>
      </:main>
    </Container>
  </template>
);
