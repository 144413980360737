import RouteTemplate from 'ember-route-template';
import pageTitle from 'ember-page-title/helpers/page-title';
import { LinkTo } from '@ember/routing';
import svgJar from 'ember-svg-jar/helpers/svg-jar';

import Container from 'frontend/components/container';
import Header from 'frontend/components/header';
import SectionFooter from 'frontend/components/section-footer';
import Sections from 'frontend/components/sections';

import styles from './legal.css';

export default RouteTemplate(
  <template>
    {{pageTitle "legal"}}

    <Container>
      <:header>
        <Header>
          <LinkTo
            data-test-menu-link
            aria-label="Show menu"
            @route="menu"
          >{{svgJar "btn-menu"}}</LinkTo>
        </Header>
      </:header>

      <:main>
        <Sections>
          <section class={{styles.legal}}>
            <div class={{styles.sub-section}}>
              {{outlet}}
            </div>
          </section>
          <SectionFooter />
        </Sections>
      </:main>
    </Container>
  </template>
);
