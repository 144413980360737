import styles from './container.css';

<template>
  <div
    class="{{styles.container}} {{if @mark styles.mark}} font-sans"
    ...attributes
  >
    <header class={{styles.header}}>
      {{yield to="header"}}
    </header>
    <main>
      {{yield to="main"}}
    </main>
    {{yield to="footer"}}
  </div>
</template>
