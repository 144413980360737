import config from 'frontend/config/environment';

const OAUTH_URL = 'https://api.instagram.com/oauth/authorize';

export function instaAuthUrl() {
  let params = new URLSearchParams({
    client_id: config.instagram.clientId,
    redirect_uri: config.instagram.redirectUri,
    response_type: 'code',
    scope: 'user_profile,user_media',
  });
  return `${OAUTH_URL}?${params.toString()}`;
}
