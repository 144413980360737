import Component from '@glimmer/component';
import RouteTemplate from 'ember-route-template';
import { LinkTo } from '@ember/routing';
import { action } from '@ember/object';
import { on } from '@ember/modifier';
import { service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';

class StripeCheckoutMockRouteComponent extends Component {
  @service session;
  @service router;
  @service api;

  @action async confirm() {
    let { jwt } = await this.stripeCheckout();
    this.router.transitionTo('subscribe-success', {
      queryParams: { token: jwt },
    });
  }

  @waitFor
  async stripeCheckout() {
    let response = await fetch('/test-stripe-checkout-mock-success-click', {
      body: '{}',
      headers: this.api.headers,
      method: 'POST',
    });
    if (response.ok) {
      return response.json();
    }
  }

  <template>
    <button
      type="button"
      {{on "click" this.confirm}}
      data-test-stripe-checkout-confirm
    >Checkout</button>

    <br />

    <LinkTo @route="index" data-test-stripe-checkout-cancel>Cancel</LinkTo>
  </template>
}

export default RouteTemplate(StripeCheckoutMockRouteComponent);
