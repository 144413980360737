import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class LoginRoute extends Route {
  @service api;
  @service router;
  @service session;

  beforeModel() {
    if (this.session.hasInstagramToken) {
      return this.router.transitionTo('subscribe');
    }
  }
}
