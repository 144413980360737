import Route from '@ember/routing/route';
import { getOwner } from '@ember/application';
import { service } from '@ember/service';
import { action } from '@ember/object';
import SimpleAnalytics from 'ember-metrics-simple-analytics';

export default class ApplicationRoute extends Route {
  @service metrics;
  @service router;
  @service session;
  @service sentry;

  constructor() {
    super(...arguments);

    getOwner(this).register(
      'metrics-adapter:simple-analytics',
      SimpleAnalytics
    );

    this.router.on('routeDidChange', () => {
      const page = this.router.currentURL;
      const title = this.router.currentRouteName || 'unknown';
      window.metrics = this.metrics;
      this.metrics.trackPage({ page, title });
    });
  }

  async beforeModel() {
    window.PictureWham = getOwner(this);
    await this.session.setup();
  }

  @action
  error(err) {
    this.sentry.captureException(err, {
      message: 'User got redirected to error page',
    });
    return true;
  }
}
