import Route from '@ember/routing/route';
import { service } from '@ember/service';
import jwt_decode from 'jwt-decode';

export default class SubscribeSuccessRoute extends Route {
  @service router;
  @service session;
  @service metrics;

  queryParams = { token: { refreshModel: true } };

  async model({ token: jwt }) {
    let {
      properties: { id },
    } = jwt_decode(jwt);
    this.metrics.trackEvent('FacebookPixel', {
      event: 'Subscribe',
      currency: 'GBP',
      value: 6.5,
      external_id: await this.session.getSHA256Hash(id),
    });
    if (jwt) {
      await this.session.authenticate('authenticator:custom', jwt);
    }
  }
}
