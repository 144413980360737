import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';

export default class SubscribeRoute extends Route {
  @service api;

  beforeModel() {
    this.photosTask.perform();
  }

  model() {
    return { photosTask: this.photosTask.last };
  }

  photosTask = task(async () => {
    return this.api.nextPhotos();
  });
}
