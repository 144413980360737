const widths = [600, 800, 1200, 1600];
const formats = ['webp', 'png'];

<template>
  <picture ...attributes>
    {{#each formats as |format|}}
      {{#each widths as |width|}}
        <source
          srcset="{{@src}}_{{width}}.{{format}}"
          media="(max-width: {{width}}px)"
          type="image/{{format}}"
        />
      {{/each}}
    {{/each}}
    <img
      alt={{@alt}}
      src="{{@src}}_1600.png"
      loading={{if @loading @loading "eager"}}
      width={{@width}}
      height={{@height}}
    />
  </picture>
</template>
