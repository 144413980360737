import Component from '@glimmer/component';
import styles from './header.css';
import { LinkTo } from '@ember/routing';

export default class HeaderForm extends Component {
  get src() {
    return this.args.mark
      ? '/images/logo-mark.svg'
      : '/images/logo-primary.svg';
  }

  get width() {
    return this.args.mark ? '56' : '346';
  }

  get height() {
    return this.args.mark ? '57' : '75';
  }

  <template>
    <div class="{{styles.header}} {{if @mark styles.mark}}" ...attributes>
      <h1>
        <LinkTo @route={{"index"}}><img
            width={{this.width}}
            height={{this.height}}
            class={{styles.logo}}
            {{! template-lint-disable require-valid-alt-text }}
            alt="Picture Wham Logo"
            src={{this.src}}
          /></LinkTo>
      </h1>
      {{yield}}
    </div>
  </template>
}
