import Component from '@glimmer/component';
import styles from './menu-nav.css';
import { instaAuthUrl } from '../helpers/insta-auth-url';
import svgJar from 'ember-svg-jar/helpers/svg-jar';
import { service } from '@ember/service';
import { on } from '@ember/modifier';
import { LinkTo } from '@ember/routing';
import externalLink from '../modifiers/external-link';
import { action } from '@ember/object';

const instagramAuthUrl = instaAuthUrl();

export default class MenuNav extends Component {
  @service api;
  @service session;

  @action logout() {
    this.session.invalidate();
  }

  instagramLink = <template>
    <a
      href={{instagramAuthUrl}}
      {{externalLink}}
      data-test-instagram-authorize-link
    ><span>{{svgJar "insta" width="16" height="16"}}Signup via Instagram</span></a>
  </template>;

  <template>
    <section class={{styles.nav-container}}>
      <nav class={{styles.nav}}>
        {{#if this.session.hasEmail}}
          <LinkTo @route="legal.terms-of-use" data-test-legal-link><span
            >{{svgJar "justice"}}Legal</span>{{svgJar "arrow-diag"}}</LinkTo>
          <a
            href="mailto:support@picturewham.com"
            {{externalLink}}
            data-test-mail-link
          ><span>{{svgJar "envelope"}}Contact</span>{{svgJar "arrow-diag"}}</a>
          <button
            type="button"
            data-test-logout-link
            {{on "click" this.logout}}
          ><span>{{svgJar "login"}}Logout</span>{{svgJar "arrow-diag"}}</button>
        {{else}}
          <LinkTo @route="login" data-test-login-link><span>{{svgJar
                "login"
              }}Login</span></LinkTo>
          {{#if this.session.hasInstagramToken}}
            <LinkTo
              type="button"
              data-test-subscribe-link
              @route="subscribe"
            ><span>{{svgJar
                  "insta"
                  width="16"
                  height="16"
                }}Subscribe</span></LinkTo>
          {{else}}
            <this.instagramLink />
          {{/if}}
        {{/if}}
      </nav>
    </section>
  </template>
}
