import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class InstagramRedirectRoute extends Route {
  @service api;
  @service router;
  @service session;

  queryParams = { code: {} };

  async model({ code }) {
    if (code) {
      let response = await this.api.saveInstagramCode(code);
      // let session do the routing depending on state contained in jwt in response header
      await this.api.handleAuthorizeHeader(response);
      await this.session.transitionToSessionState();
    } else {
      // if no code then auth has probably been cancelled by user so just go to index
      if (this.session.hasStripeCustomer) {
        this.router.transitionTo('authenticated.index');
      } else {
        this.router.transitionTo('index');
      }
    }
  }
}
