import Service, { service } from '@ember/service';
import config from 'frontend/config/environment';
import { waitFor } from '@ember/test-waiters';

export default class ApiService extends Service {
  @service location;
  @service session;
  @service metrics;

  async fetch() {
    let result = await window.fetch(...arguments);
    if (result.status === 401) {
      this.session.invalidate();
    }
    return result;
  }

  async handleAuthorizeHeader(response) {
    let auth = response.headers.get('authorize');
    if (auth) {
      let [scheme, value] = auth.split(' ');
      if (scheme === 'Bearer') {
        await this.session.authenticate('authenticator:custom', value);
      }
    }
  }

  async country() {
    // whitleist listed picturewham.com
    let result = await window.fetch();
    if (result.ok) {
      let info = await result.json();
      return info.country;
    } else {
      this.sentry.captureException(new Error('unable to fetch country'));
    }
    return 'GB';
  }

  @waitFor
  async saveInstagramCode(code) {
    let response = await this.fetch(`${config.hosts.apiUrl}/instagram-auth`, {
      body: JSON.stringify({ code }),
      headers: this.headers,
      method: 'POST',
    });
    if (response.ok) {
      return response;
    } else {
      throw new Error('Error saving instagram code', { cause: response });
    }
  }

  @waitFor
  async checkout(countryCode) {
    this.metrics.trackEvent('FacebookPixel', {
      event: 'InitiateCheckout',
      external_id: this.session.getSHA256Hash(this.session.id),
    });
    let response = await this.fetch(
      `${config.hosts.apiUrl}/create-checkout-session`,
      {
        body: JSON.stringify({
          countryCode,
        }),
        headers: this.headers,
        method: 'POST',
      }
    );
    if (response.ok) {
      let { redirect } = await response.json();
      this.location.href = redirect;
    } else {
      console.log(response);
      throw new Error('Error subscribing');
    }
  }

  @waitFor
  async visitCustomerBilling() {
    let response = await this.fetch(
      `${config.hosts.apiUrl}/create-customer-billing-session`,
      {
        body: '{}',
        headers: this.headers,
        method: 'POST',
      }
    );
    if (response.ok) {
      let { redirect } = await response.json();
      this.location.href = redirect;
    } else {
      throw new Error('Error redirecting to billing');
    }
  }

  @waitFor
  async login(formData) {
    let authUrl = new URL(`${config.hosts.apiUrl}/auth/link/authorize`);
    authUrl.searchParams.set('email', formData.get('email'));
    let response = await this.fetch(authUrl);
    if (response.status === 200) {
      // returns 201 usually unless test token link to display
      return await response.json();
    } else if (!response.ok) {
      throw Error('error', { cause: response });
    }
  }

  @waitFor
  async instagramUsername() {
    if (this.session.hasInstagramToken) {
      let response = await this.fetch(
        `${config.hosts.apiUrl}/instagram-username`,
        {
          headers: this.headersFor('GET'),
          method: 'GET',
        }
      );
      if (response.ok) {
        let { username } = await response.json();
        return username;
      } else {
        throw new Error('Error getting instagram username', {
          cause: response,
        });
      }
    }
  }

  async nextPhotos() {
    if (this.session.hasInstagramToken) {
      if (this._photos) return this._photos;
      let response = await this.fetch(`${config.hosts.apiUrl}/next-media`, {
        headers: this.headersFor('GET'),
        method: 'GET',
      });
      if (response.ok) {
        this._photos = await response.json();
        return this._photos;
      } else {
        throw new Error('Error getting next media', { cause: response });
      }
    }
    return { media: [], timeRange: null };
  }

  get headers() {
    return this.headersFor();
  }

  headersFor(verb) {
    let headers = {};
    let jwt = this.session.data.authenticated.jwt;
    if (jwt) {
      headers['Authorization'] =
        `Bearer ${this.session.data.authenticated.jwt}`;
    }
    if (verb !== 'GET') {
      headers['Content-Type'] = 'application/json';
    }
    return headers;
  }
}
