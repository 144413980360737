import styles from './loading.css';
import Loader from 'frontend/components/loader';

<template>
  <main class={{styles.main}}>
    <img
      {{! template-lint-disable require-valid-alt-text }}
      alt=""
      src="/images/logo-primary.svg"
      width="346"
      height="75"
    />

    <Loader />
  </main>
</template>
