import styles from './link.css';
import { LinkTo } from '@ember/routing';

<template>
  {{#if @button}}
    <button class={{styles.container}} type="button" ...attributes><div
        class="{{styles.link}} {{if @darkBackground styles.dark-bg}}"
      >{{yield}}</div></button>
  {{else if @route}}
    <LinkTo
      data-test-login-link
      class={{styles.container}}
      @route={{@route}}
      ...attributes
    ><div
        class="{{styles.link}} {{if @darkBackground styles.dark-bg}}"
      >{{yield}}</div></LinkTo>
  {{else}}
    <a
      href=""
      rel="noopener noreferrer"
      class={{styles.container}}
      ...attributes
    ><div
        class="{{styles.link}} {{if @darkBackground styles.dark-bg}}"
      >{{yield}}</div></a>
  {{/if}}
</template>
