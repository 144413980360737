import Modifier from 'ember-modifier';
import { registerDestructor } from '@ember/destroyable';
import { action } from '@ember/object';
import { service } from '@ember/service';

function cleanup(instance) {
  instance.element.removeEventListener('click', instance.handler);
  instance.element = null;
}

export default class ExternalLinkModifier extends Modifier {
  @service externalLinkManager;

  element = null;

  modify(element) {
    this.element = element;
    element.addEventListener('click', this.handler);
    registerDestructor(this, cleanup);
  }

  @action handler(event) {
    this.externalLinkManager.onClick(event);
  }
}
