import Component from '@glimmer/component';
import svgJar from 'ember-svg-jar/helpers/svg-jar';
import RouteTemplate from 'ember-route-template';
import pageTitle from 'ember-page-title/helpers/page-title';
import { service } from '@ember/service';
import { LinkTo } from '@ember/routing';
import Container from 'frontend/components/container';
import Header from 'frontend/components/header';
import Loader from 'frontend/components/loader';
import Section from 'frontend/components/section';
import SectionFooter from 'frontend/components/section-footer';
import Sections from 'frontend/components/sections';
import SubscribeForm from 'frontend/components/subscribe-form';
import Picture from 'frontend/components/picture';
import PhotoPreview from 'frontend/components/photo-preview';
import { dropTask } from 'ember-concurrency';
import perform from 'ember-concurrency/helpers/perform';
import styles from './subscribe.css';

class SubscribeRouteComponent extends Component {
  @service api;

  submitTask = dropTask(async (key) => {
    await this.api.checkout(key);
  });

  <template>
    {{pageTitle "Subscribe"}}

    <Container>
      <:header>
        <Header>
          <LinkTo
            data-test-menu-link
            aria-label="Show menu"
            @route="menu"
          >{{svgJar "btn-menu"}}</LinkTo>
        </Header>
      </:header>
      <:main>
        <Sections>
          <Section class={{styles.section}}>
            {{#if @model.photosTask.isRunning}}
              <Loader class={{styles.main}} />
            {{else if @model.photosTask.isSuccessful}}
              <PhotoPreview
                class={{styles.photos}}
                @columns={{3}}
                @photos={{@model.photosTask.value.media}}
              />
            {{else if @model.photosTask.isError}}
              <Picture
                class={{styles.picture}}
                @alt="Looking into the digital abyss as it swirls around the #ootd"
                @width="1600"
                @height="1526"
                @src="/images/illustrations/abyss"
              />

            {{/if}}
            <p class={{styles.text}}>
              To print specific images from last month tag them with
              <em>#picturewham</em>
              on Instagram and refresh this page.</p>
            <SubscribeForm @onSubmit={{perform this.submitTask}} />
          </Section>
          <SectionFooter />
        </Sections>

      </:main>
    </Container>
  </template>
}

export default RouteTemplate(SubscribeRouteComponent);
