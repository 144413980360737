import { isTesting, macroCondition } from '@embroider/macros';

export const LOGIN_FORM_TIMEOUT = macroCondition(isTesting()) ? 0 : 1000;

export const COST_LOOKUP = {
  EU: { currencySymbol: '€', amount: '9.99' },
  GB: { currencySymbol: '£', amount: '6.99' },
  NL: { currencySymbol: '€', amount: '11.50' },
  US: { currencySymbol: '$', amount: '14.99' },
};

// TODO show non EUR currency to EU countries not using EUR
export const EU_COUNTRY_CODES = [
  'AT',
  'BE',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'HU',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SK',
  'SI',
  'ES',
  'SE',
];

export const COUNTRY_CODES = [...EU_COUNTRY_CODES, 'GB', 'US'];
