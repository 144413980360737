import styles from './get-started-button.css';
import Link from './link';
import vibrate from '../modifiers/vibrate';
import externalLink from '../modifiers/external-link';
import { instaAuthUrl } from '../helpers/insta-auth-url';
import svgJar from 'ember-svg-jar/helpers/svg-jar';

const instagramAuthUrl = instaAuthUrl();

export const CheckoutButton = <template>
  <Link
    ...attributes
    class="{{styles.insta}}"
    @button={{true}}
    @darkBackground={{@darkBackground}}
    data-test-subscribe-button
  >{{yield}}</Link>
</template>;

const CheckoutLink = <template>
  <Link
    ...attributes
    class="{{styles.insta}}"
    @route="subscribe"
    @darkBackground={{@darkBackground}}
    data-test-subscribe-button
  >{{yield}}</Link>
</template>;

const OAuthButton = <template>
  <Link
    ...attributes
    class="{{styles.insta}}"
    href={{instagramAuthUrl}}
    {{externalLink}}
    {{vibrate}}
    @darkBackground={{@darkBackground}}
    data-test-instagram-authorize-link
  >{{svgJar "insta"}}&nbsp;Get Started via Instagram</Link>
</template>;

<template>
  {{#if @hasInstagramToken}}
    <CheckoutLink ...attributes @darkBackground={{@darkBackground}}>Get Started
      and Subscribe</CheckoutLink>
  {{else}}
    <OAuthButton ...attributes @darkBackground={{@darkBackground}} />
  {{/if}}
</template>
