import Base from 'ember-simple-auth/authenticators/base';
import jwt_decode from 'jwt-decode';

export default class CustomAuthenticator extends Base {
  async restore(data) {
    // presume is authenticated until 401 from API
    return data;
  }

  // user has already been authenticated with login link
  // so we just decode token and return it properties
  async authenticate(jwt) {
    let { properties } = jwt_decode(jwt);
    let {
      eit,
      he: hasEmail,
      hit: hasInstagramToken,
      hsc: hasStripeCustomer,
      sss: stripeSubscriptionStatus,
      id,
    } = properties;
    return {
      eit,
      hasEmail,
      hasInstagramToken,
      hasStripeCustomer,
      stripeSubscriptionStatus,
      id,
      jwt,
    };
  }
}
