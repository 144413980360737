import BaseSessionService from 'ember-simple-auth/services/session';
import { service } from '@ember/service';

const ACTIVE = 'active';

export default class SessionService extends BaseSessionService {
  @service api;
  @service router;

  get hasInstagramToken() {
    return this.data.authenticated.hasInstagramToken;
  }

  get hasStripeCustomer() {
    return this.data.authenticated.hasStripeCustomer;
  }

  get isSubscriptionActive() {
    return this.stripeSubscriptionStatus === ACTIVE;
  }

  get stripeSubscriptionStatus() {
    return this.data.authenticated.stripeSubscriptionStatus;
  }

  get hasEmail() {
    return this.data.authenticated.hasEmail;
  }

  get idHash() {
    return this.data.authenticated.id;
  }

  async getSHA256Hash(input) {
    const textAsBuffer = new TextEncoder().encode(input);
    const hashBuffer = await window.crypto.subtle.digest(
      'SHA-256',
      textAsBuffer
    );
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hash = hashArray
      .map((item) => item.toString(16).padStart(2, '0'))
      .join('');
    return hash;
  }

  handleAuthentication() {
    this.transitionToSessionState();
  }

  handleInvalidation() {
    this.router.transitionTo('index');
  }

  transitionToSessionState() {
    if (this.hasEmail) {
      if (this.hasInstagramToken) {
        return this.router.transitionTo('authenticated.index');
      } else {
        return this.router.transitionTo('authenticated.instagram-authorize');
      }
    } else {
      if (this.hasInstagramToken) {
        return this.router.transitionTo('subscribe');
      } else {
        return this.router.transitionTo('instagram-authorize');
      }
    }
  }
}
