import styles from './footer.css';
import { LinkTo } from '@ember/routing';

<template>
  <footer class={{styles.footer}} ...attributes>
    <img
      width="238"
      height="51"
      class={{styles.logo}}
      {{! template-lint-disable require-valid-alt-text }}
      alt="Picture Wham Logo"
      src="/images/logo-primary-dark.svg"
    />
    <span class={{styles.copyright}}>Copyright © 2023 mrloop ltd.</span>
    <ul class={{styles.links}}>
      <li><LinkTo @route="legal.terms-of-use">Legal</LinkTo></li>
      <li><a href="mailto:support@picturewham.com">Contact</a></li>
    </ul>
  </footer>
</template>
