import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class IndexRoute extends Route {
  @service router;
  @service session;

  async beforeModel() {
    if (this.session.hasEmail) {
      this.router.transitionTo('authenticated');
    }
  }
}
